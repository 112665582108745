.c-about {
  padding: 30px 2rem 4.5rem;
  text-align: center;
  letter-spacing: 1.2px;


  .alm-btn-wrap {
    display: block;
  }
}

.c-about__icon {
  margin-top: 2rem;

  img {
    @include iconAboveTitle();
  }
}

.c-about-title {
  @include heading();
}

.c-about-content--hidden {
  display: none;
}
