/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1150px) {
    flex-direction: row;
  }
}

.c-main-nav__item {
  font-weight: 700;
  list-style: none;
  padding: 0;
  margin-bottom: 0.1rem;
}

.c-main-nav__link {
  display: block;
  padding: 0 1.3rem;
  text-decoration: none;
  color: $color-medium-grey;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1rem;
}

.current_page_item a {
  color: $color-orange;
}

.page-template-template-home .current_page_item a {
  color: $color-medium-grey;
}