.c-before-footer {
  background: $color-orange;
  padding: 4rem 1rem;
}

.c-before-footer__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .c-before-footer__contacts {
    margin-top: 1.5rem;
    letter-spacing: 1.5px;
    font-weight: 600;
  }

  @include bp(medium) {
    flex-direction: row;

    .c-before-footer__logo-wrapper {
      align-self: center;
    }

    .c-before-footer__contacts {
      margin-top: 0;
    }
  }

  > div {
    @include bp(medium) {
      width: 49%;
    }
  }
}

.c-before-footer__logo {
  max-width: 12.5rem;
  width: 100%;
}

.c-before-footer__contacts {
  display: flex;
  justify-content: space-between;
  color: $color-white;
  font-size: 0.875rem;
  flex-direction: column;

  @include bp(medium) {
    flex-direction: row;
  }
}

.c-before-footer__addresses {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: $color-white;
    text-decoration: none;
    font-weight: 600;
  }
  > div {
    display: flex;
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 0.9rem;
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
  }
}
