.c-divider {
  height: 150px;
  background: {
    position: 50% 50%;
    size: cover;
    repeat: no-repeat;
  }
  margin: 0 2rem;

  @include bp(medium) {
    height: 250px;
  }
}