@import 'flickity/css/flickity';

.flickity-page-dots {
  display: none;
  @include bp(medium) {
    display: block;

    right: 20px;
    text-align: right;
    bottom: 20px;
    z-index: 20;
  }

  .dot {
    background: $color-white;
  }
}