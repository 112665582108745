/*
  Project: el-molo
  Author: Daniel
 */

@import "settings/_global.scss";
@import "tools/_breakpoints.scss";
@import "tools/_clearfix.scss";
@import "tools/_hidden.scss";
@import "tools/_icons.scss";
@import "tools/_paddingor.scss";
@import "tools/_typo.scss";
@import "generic/_box-sizing.scss";
@import "generic/_font-face.scss";
@import "generic/_normalize.scss";
@import "generic/_reset.scss";
@import "generic/_shared.scss";
@import "elements/_blockquote.scss";
@import "elements/_headings.scss";
@import "elements/_hr.scss";
@import "elements/_html.scss";
@import "elements/_images.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_tables.scss";
@import "objects/_layout.scss";
@import "objects/_list-bare.scss";
@import "objects/_list-inline.scss";
@import "objects/_media.scss";
@import "objects/_table.scss";
@import "objects/_wrapper.scss";
@import "components/_about.scss";
@import "components/_article.scss";
@import "components/_before-footer.scss";
@import "components/_btn.scss";
@import "components/_circle-icon.scss";
@import "components/_comment-form.scss";
@import "components/_comment.scss";
@import "components/_contact-form.scss";
@import "components/_contact.scss";
@import "components/_cookies.scss";
@import "components/_divider.scss";
@import "components/_download.scss";
@import "components/_footer.scss";
@import "components/_gallery.scss";
@import "components/_header.scss";
@import "components/_intro-text.scss";
@import "components/_main-nav.scss";
@import "components/_menu.scss";
@import "components/_post-nav.scss";
@import "components/_post.scss";
@import "components/_services.scss";
@import "components/_topbar.scss";
@import "utilities/_align.scss";
@import "utilities/_aspect-ratio.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_helpers.scss";
@import "utilities/_hide.scss";
@import "utilities/_show-more.scss";
@import "vendor/_flickity.scss";
@import "vendor/_lightbox.scss";
@import "vendor/_map.scss";

.g-recaptcha {
  padding-top: 20px;
  margin-bottom: 20px;
}