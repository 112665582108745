.alm-load-more-btn {
  display: inline-block;
  margin-top: 50px;
  background: none!important;
  border: none!important;
  cursor: pointer;
  outline: none;
  height: auto!important;

  .u-show-more--plus {
      font-size: 1.4em;
      font-weight: 500;
      color: $color-white;
      width: 1.55rem;
      height: 1.55rem;
      border-radius: 50%;
      line-height: 1!important;
      background: $color-orange;
      display: inline-block;
  }
  .u-show-more--text {
      color: $color-orange;
      font-weight: 700;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      line-height: 1.4;
  }
}

.alm-btn-wrap {
  text-align: center;

  .done {
      display: none!important;
  }
}
