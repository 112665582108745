.c-contact-form {
  max-width: 44rem;
  width: 100%;
  margin: 3em auto 0;
  text-align: left;
}

.c-contact-form__group {
  input,
  textarea {
    width: 100%;
    background: transparent;
    margin-bottom: 1rem;
    border: none;
    appearance: none;
    border: 1px solid $color-medium-grey;
    padding: 0.8rem 1rem;
    letter-spacing: 1.4px;
    color: $color-orange;
    font-size: 0.75rem;

    &::placeholder {
      color: $color-orange;
      font-weight: 700;
      text-transform: uppercase;
    }

    &:focus {
      outline: 2px solid $color-orange;
    }
  }

  input {
    text-align: center;
  }
}

.c-contact-form__info, .c-contact-form__terms {
  font-size: 0.85rem;
}

.c-contact-form__terms {
  margin-top: 0.3rem;
  a {
    text-transform: uppercase;
  }
}

.c-form__message {
  margin: 3em auto 0 auto;
  border: 2px solid $color-very-light-grey;
  padding: 1em;
  max-width: 25rem;
}

.c-form__message--thank-you {
  border-color: $color-black;
  color: $color-black;
}

.c-form__message--error {
  border-color: $color-orange;
  color: $color-orange;
}

.c-contact-form__recaptcha {
  text-align: center;
  padding: 1rem;
}

.g-recaptcha {
  display: inline-block;
}
@media (max-height: 575px){
  #rc-imageselect, .g-recaptcha {transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;}
  }