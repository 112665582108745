/* ==========================================================================
   #HEADER
   ========================================================================== */


  .c-header {
  position: relative;
  margin: 0 1.8rem;
  height: calc(100vh - 12rem);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.c-header__slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.c-header__image {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.c-header-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  .c-header-scroll {
    display: inline-block;
    font-size: 0.85rem;
    text-transform: uppercase;
    color: $color-white;
    padding: 20px;
    text-decoration: none;

    span {
      position: relative;
      margin-right: 10px;
      font-weight: 700;
      top: 2px;
      text-transform: uppercase;
    }

    img {
      width: 35px;
      height: 35px;
    }
  }
}

.svg-mouse {
  fill: $color-white;
  width: 3rem;
  display: block;
  margin: 0 auto;
}

.svg-mouse__arrow {
  animation: arrowMove 2s infinite;
}


@keyframes arrowMove {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }

  to {
    transform: translateY(0);
  }
}

.c-header__bubble {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  border-radius: 50%;
  background: $color-orange;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include bp(medium) {
    max-width: 330px;
    max-height: 330px;
  }
}

.c-header__bubble__logo {
  margin-top: 20px;

  img {
    width: 140px;
    @include bp(medium) {
      width: 180px;
    }
  }
}

.c-header__bubble__icon {
  margin: 20px auto 15px;

  img {
    width: 65px;
    @include bp(medium) {
      width: 95px;
    }
  }
}

.c-header__bubble__title {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: $color-white;
  text-align: center;
}
