/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: underline;
  font-weight: 700;

  &:hover {
    color: $color-hover;
  }
}
