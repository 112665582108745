.c-menu {
  padding: 30px 2rem 5rem;
  text-align: center;
  letter-spacing: 1.2px;
}

.c-menu__icon {
  margin-top: 2rem;

  img {
    @include iconAboveTitle();
  }
}

.c-menu-title {
  @include heading();
}

.c-menu__wrapper--homepage {
  display: flex;
  max-width: 1100px;
  margin: 4rem auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.c-menu__item--homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  width: 49%;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;

  @media(min-width: 550px) {
    width: 32%;
  }

  @include bp(medium) {
    margin-top: 0.5rem;
    width: 18%;
    flex: 0 0 auto;
  }
}

.c-menu__item__title--homepage {
  font-size: 0.85rem;
  padding: 0.5rem 0.8rem;
  margin-top: 0.5rem;
  color: $color-dark-grey;
  letter-spacing: 0;
}

.c-menu__list {
  max-width: 1000px;
  margin: 0 auto !important;
  padding: 0 1.4rem !important;
}

.c-menu__list-item__title {
  margin: 0.8rem 0;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.c-menu__list-item__description {
  margin: -0.9rem 0 0.8rem;
  text-align: center;
  font-weight: 700;
  font-size: 0.7rem;
}

.c-menu__list-item__list {
  margin-top: 3rem;
}

.c-menu__list-item__item {
  display: flex;
}

.c-menu__list-item__item-position {
  margin-right: 10px;
  font-weight: 700;

  @include bp(medium) {
    width: 80px;
    margin-right: 0;
  }
}

.c-menu__list-item__item-content {
  display: flex;
  width: calc(100% - 50px);
  padding-bottom: 40px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  &:after {
    content: '...........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................';
    position: absolute;
    letter-spacing: 2px;
    font-size: 10px;
    color: $color-medium-grey;
    display: inline-block;
    vertical-align: 3px;
    bottom: 0;
    left: 0;
  }
}

.c-menu__list-item__item-content__main {
  padding-right: 30px;
}

.c-menu__list-item__item-content__price {
  margin-left: auto;
  white-space: nowrap;
  font-weight: 700;
}