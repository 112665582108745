.c-contact {
  padding: 30px 2rem 5rem;
  text-align: center;
  letter-spacing: 1.2px;
}

.c-contact__icon {
  margin-top: 2rem;

  img {
    max-height: 45px;
    height: 100%;
  }
}

.c-contact-title {
  @include heading();
}
