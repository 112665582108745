.c-download {
  margin: 3rem auto 1rem;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 0 2rem;
}

.c-download-title {
  @include heading();
}

.c-download-list__item--icon {
  max-width: 5rem;
  margin: 2rem auto 0.7rem;
}

.c-download-list__item--title {
  font-size: 0.9rem;
  text-decoration: none;
  color: $color-black;
  display: inline-block;
  font-weight: 400;
}

.c-download-navigation-prev {
  display: inline-block;
  margin-top: 3rem;
  text-decoration: none;
  margin-bottom: 0.7rem;
}

.c-download-navigation-prev__icon img {
  width: 20px;
}

.c-download-navigation-text {
  font-size: 0.8rem;
  color: $color-orange;
}
