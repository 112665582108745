.c-circle-icon {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background: $color-orange;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.c-circle-icon--img {
  width: 4.5rem;
  height: 4.5rem;
}
