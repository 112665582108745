.c-services {
  padding: 30px 2rem 5rem;
  text-align: center;
  letter-spacing: 1.2px;
}

.c-services-title {
  @include heading();
}

.c-services__icon {
  margin-top: 2rem;
  img {
    @include iconAboveTitle();
  }
}

.c-services__wrapper {
  display: flex;
  max-width: 1100px;
  margin: 4rem auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.c-services__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  width: 49%;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;

  @media(min-width: 550px) {
    width: 32%;
  }

  @include bp(medium) {
    margin-top: 0.5rem;
    width: 18%;
    flex: 0 0 auto;
  }
}

.c-services__item__title {
  font-size: 0.85rem;
  padding: 0.5rem 0.8rem;
  margin-top: 0.5rem;
  color: $color-dark-grey;
  letter-spacing: 0;
}

.c-service-item__decription, .c-service-item__description-more {
  text-align: left;
  padding: 0 1em;
  font-size: 1rem;
}
.c-service-item__heading {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.c-services__list-item {
  margin-bottom: 55px;
  text-align: center;

  .c-btn__more {
    display: inline-block;
  }
}
.c-services__list-item__title {
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
  text-align: center;
}

.c-services__list-item__description {
  text-align: center;
  font-size: 0.9rem;
  padding-bottom: 40px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;

  &:after {
    content: '...........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................';
    position: absolute;
    letter-spacing: 2px;
    font-size: 10px;
    color: $color-medium-grey;
    display: inline-block;
    vertical-align: 3px;
    bottom: 0;
    left: 0;
  }
}

.c-services__list {
  max-width: 1000px;
  margin: 3rem auto !important;
  padding: 0 1.4rem !important;
}
