.c-post-nav {
  max-width: 1000px;
  margin: 2rem auto 5rem;
  padding: 0 1.4rem;
  display: flex;
  justify-content: space-between;
}

.c-post-nav__item {
  text-decoration: none;
}

.c-post-nav__item__title {
  color: $color-orange;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: center;
}

.c-post-nav__item__icon {
  background: $color-orange;
  margin: 0.9rem auto 0;
  width: 2.9rem;
  height: 2.9rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  // align-items: center;
  color: $color-white;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.7;
}

.c-post-nav__item__icon--download {
  width: 65%;
}

.c-post-nav__item__icon--mail {
  width: 55%;
}