/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: $color-black;
}

.c-footer__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-white;
  font-size: 0.7rem;
  padding: 0.7rem 1rem;
  flex-direction: column;
  justify-content: center;

  @include bp(medium) {
    flex-direction: row;

    a {
      margin-left: auto;
    }
  }

  p {
    margin: 0;
  }

  a {
    color: $color-white;
    text-decoration: none;
    font-weight: 400;
  }
}
