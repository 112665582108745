/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

 /* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@font-face {
  font-family: 'Conv_MyriadPro-Regular';
  src: url('../assets/fonts/MyriadPro-Regular.eot');
  src: local('☺'), url('../assets/fonts/MyriadPro-Regular.woff') format('woff'), url('../assets/fonts/MyriadPro-Regular.ttf') format('truetype'), url('../assets/fonts/MyriadPro-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_MyriadPro-It';
  src: url('../assets/fonts/MyriadPro-It.eot');
  src: local('☺'), url('../assets/fonts/MyriadPro-It.woff') format('woff'), url('../assets/fonts/MyriadPro-It.ttf') format('truetype'), url('../assets/fonts/MyriadPro-It.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_MyriadPro-Bold';
  src: url('../assets/fonts/MyriadPro-Bold.eot');
  src: local('☺'), url('../assets/fonts/MyriadPro-Bold.woff') format('woff'), url('../assets/fonts/MyriadPro-Bold.ttf') format('truetype'), url('../assets/fonts/MyriadPro-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_MyriadPro-BoldIt';
  src: url('../assets/fonts/MyriadPro-BoldIt.eot');
  src: local('☺'), url('../assets/fonts/MyriadPro-BoldIt.woff') format('woff'), url('../assets/fonts/MyriadPro-BoldIt.ttf') format('truetype'), url('../assets/fonts/MyriadPro-BoldIt.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
