/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.5rem 1rem;
  transition: $global-transition;
  border-radius: $global-radius;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-link;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: $color-hover;
  }
}

.c-btn--secondary {
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid $color-orange;
  font-weight: 700;
  text-decoration: none;
  color: $color-orange;
  padding: 10px 15px;
  font-size: 0.9rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $color-orange;
    color: $color-white;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* Border color variants
   ========================================================================== */

   .c-btn--white {
    border-color: $color-white;
    color: $color-white;
    transition: all 0.3s ease-in;
  
    &:hover {
      background: $color-white;
      color: $color-black;
    }
  }
  
  .c-btn--orange {
    background: $color-orange;
    color: $color-white;
    text-transform: uppercase;
    font-weight: 700;
  
    a {
      color: $color-white;
      text-transform: uppercase;
    }
  
    &:hover {
      background-color: transparent;
      border-color: $color-orange;
      color: $color-orange;
  
      a {
        color: $color-orange;
      }
    }
  }
  .c-btn--send {
    width: auto;
    border: 1px solid $color-orange;
    margin: 0 auto;
    display: block;
    padding: 0.5rem 2.5rem
  }
  .c-btn-go-back-wrap {
    width: 100%;
    text-align: center;
  }
  
  .c-btn-go-to-website {
    text-align: center;
    display: inline-block;
  }
  
  .big-red-arrow {
    font-size: 24px;
    font-weight: 700;
    color: $color-orange;
    display: inline-block;
  }
  // Load more BTN

  .c-btn-wrapper {
    padding: 30px 10px;
    text-align: center;
  }

  .alm-load-more-btn.loading {
    display: none!important;
  }