.c-gallery__wrapper {
  max-width: 1100px;
  margin: 0 auto !important;
  padding: 1rem 1rem 4rem !important;
  display: flex;
  flex-wrap: wrap;
}

.c-gallery__item {
  margin: 2rem auto 0;
  display: block;
  flex: 1 0 auto;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.c-gallery__item__img {

}