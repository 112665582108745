@import 'leaflet/dist/leaflet';

#map {
  height: 60vh;

  img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  .leaflet-marker-pane {
    img {
      filter: none;
      -webkit-filter: none;
    }
  }
}