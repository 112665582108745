.c-topbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: $color-white;
  z-index: 9999;
}

.c-topbar__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.8rem;
}

.c-topbar__logo-holder {
  width: 49%;
}

.c-topbar__logo {
  height: 3rem;
  margin-left: 2rem;

  @include bp(medium) {
    margin-left: 7rem;
  }
}

.c-topbar__options {
  display: none;

  @media (min-width: 1150px) {
    display: flex;
    margin-left: auto;
    align-items: center;
    margin-right: 2rem;
    position: relative;
  }
}

.c-topbar__hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
  position: relative;

  span {
    width: 1.7rem;
    height: 0.2rem;
    background: $color-orange;
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1150px) {
    display: none;
  }
}

.c-topbar__menu {
  display: none;
  position: absolute;
  background: $color-white;
  z-index: 9999999;
  left: 1.3rem;
  right: 1.3rem;
  top: 4.8rem;
  min-height: 200px;
  cursor: initial;
  border: 1px solid $color-light-grey;

  @media (max-width: 1150px) {
    .c-main-nav__link {
      font-size: 1.5rem;
    }
  }
}

.menu-active {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.c-topbar__langs {
  margin-left: 0;
  display: flex;
  margin-bottom: 0;
  align-items: center;

  @media (max-width: 1150px) {
    justify-content: center;
  }

  .lang-item {
    padding-left: 0;
    list-style-type: none;
    margin-left: 0.5rem;
    position: relative;
    width: 1rem;
    @media (max-width: 1150px) {
      width: 1.5rem;
    }

    &:first-child {
      margin-left: 0;
    }

    span {
      display: none;
    }

    img {
      width: 1rem;
      @media (max-width: 1150px) {
        width: 1.5rem;
      }
      display: inline-block;
    }
  }

  .current-lang::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 4px;
    border: solid $color-orange;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    @media (max-width: 1150px) {
      left: 6.5px;
    }
  }
}

.c-topbar__social {
  display: flex;
  align-items: center;
  margin: 0;
  position: relative;
  padding: 0 1.7rem;
  margin: 0.7rem 0;
  justify-content: center;

  @media (min-width: 1150px) {
    &:after {
      content: ".......";
      letter-spacing: -1px;
      transform: rotate(90deg);
      position: absolute;
      right: 0;
      top: 0;
    }
    &:before {
      content: ".......";
      letter-spacing: -1px;
      transform: rotate(90deg);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.c-topbar__social-wrap {
  list-style-type: none;
  display: inline-block;
  &:first-child {
    margin-right: 0.8rem;
  }
  @media (min-width: 1150px) {
    &:last-child {
      margin-right: 0.5rem;
    }
  }
}

.c-topbar__social-icon {
  width: 1.2rem;
}
